$brandHeight: 130px;
$dtHeight: 100px;
$drawerWidth: 250px;

.primary_navigation {
  .brand_p0 {
    height: $brandHeight;

    .brand_title {
      margin-top: 0.5em;
      color: $textLight2;
      font-size: 0.85rem;
    }
  }

  nav {
    height: 100px;
    overflow-y: auto;
    height: calc(100vh - ($brandHeight + $dtHeight));
  }
}

.bg_overlay_28 {
  position: fixed;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.295);
  width: 100%;
  height: 100vh;
  transform: translateX(-100%);
  @include Smooth(0.3s);
  &.open {
    transform: translateX(0);
  }
}

.dt_wrapper {
  height: $dtHeight;

  p {
    margin: 0;
    color: #000;
    font-size: 0.95rem;

    span {
      display: block;
      color: $textLight2;
    }
  }
}

.primary_navigation {
  .nav_item {
    @include Smooth(0.2s);
  }

  .nav_link {
    .icon_wrapper {
      width: 35px;
      height: 35px;
      color: #000000;
      background-color: #f5f5f5;
      @include Smooth(0.2s);
    }

    .link_txt {
      font-size: 0.95rem;
      color: #000;
      @include Smooth(0.2s);
    }
  }

  .nav_item.active,
  .nav_item:hover {
    background-color: #000;

    .icon_wrapper {
      color: #000;
      background-color: #fff;
    }

    .link_txt {
      color: #fff;
    }
  }
}

@media (min-width: 992px) {
  .primary_navigation nav {
    @include ScrollBar();
  }
}
@media (max-width: 991px) {
  .primary_navigation {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-$drawerWidth);
    @include Smooth(0.4s);
    &.open {
      transform: translateX(0);
    }
  }
}
