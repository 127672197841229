@mixin Smooth($x) {
    transition: $x all ease-in-out;
}

@mixin ScrollBar {
    scrollbar-width: thin;
    scrollbar-color: #333 #ccc;
    &::-webkit-scrollbar{
        width: 7px;
        height: 7px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #333;
    }
    &::-webkit-scrollbar-track{
        background-color: #ccc;
    }
}