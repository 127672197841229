.signin_page{
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.signin_page .form_wrapper{
    background-color: #fff;
    max-width: 550px;
    margin: 0 auto;
}